import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const CashierFormView = ({
  session,
  setLoadingPaymentMethod,
  form,
  canLoadForm,
}) => {
  if (session?.success && session?.error_code === 'PCC-509') {
    return <Redirect to="/account/verification_zone" />;
  }

  if (!session?.success || !canLoadForm) return null;

  return (
    <div className="asia_cashier__form">
      <form
        action={session.url}
        method="post"
        target="cashier-frame"
        ref={form}
      >
        {Object.entries(session.parameters).map(([name, value]) => (
          <input type="hidden" name={name} value={value} key={name} />
        ))}
      </form>
      <iframe
        className={`cashier-frame cashier_${session.method}`}
        title="cashier_iframe"
        name="cashier-frame"
        frameBorder={0}
        scrolling="auto"
        seamless
        onLoad={() => setLoadingPaymentMethod(false)}
      />
    </div>
  );
};

CashierFormView.defaultProps = {
  session: null,
};

CashierFormView.propTypes = {
  session: PropTypes.instanceOf(Object),
  form: PropTypes.instanceOf(Object).isRequired,
  canLoadForm: PropTypes.bool.isRequired,
  setLoadingPaymentMethod: PropTypes.func.isRequired,
};

export default CashierFormView;
